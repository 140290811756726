import React, { useState } from "react";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import UnorderedList from "~/components/List/Unordered";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);
  return (
    <LessonLayout
      current_step={6}
      lesson={"Lesson 5"}
      color={"hs"}
      total_count={7}
      module_title="Putting Your Rubric to Work"
    >
      <ContentWrapper>
        <H2 underline>Step 6</H2>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={true}
            setFullScreen={setStudentFullScreen}
          >
            <p>Now that you have tested your rubric, you are ready to give it to someone who has never seen it before. How would you explain how it is used and why a rubric can be helpful when a person needs to analyze information?</p>
            <p>After you write some of your ideas in your science notebook, your teacher will lead a class discussion.</p>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={true}
            setFullScreen={setTeacherFullScreen}
          >
            <p>Give students a few minutes to record ideas in their notebook about how and why someone could use the rubric. Then hold a class discussion to allow students to share their ideas.</p>

            <p>Ideas that may arise during the discussion include the following:</p>
            <UnorderedList>
              <li>It might be helpful to explain to someone what the different categories are and how you make decisions about a score.</li>
              <li>Individuals can assign scores based on their own situation. Scores do not have to agree with others as long as the reasons are valid.</li>
              <li>A rubric can help people think about an issue from all sides, including the positives and potential negatives.</li>
              <li>A rubric encourages people to think about the evidence rather than relying on the advertising wording or other intangible influences.</li>
              <li>It is unlikely that anyone would use the rubric repeatedly over time, but familiarity and some practice with it encourages a habit of mind whereby people think along the lines of the different categories automatically.</li>
              <li>Some people assume that information must be accurate if it is included in a media piece.</li>
              <li>Health information is difficult for many people to understand. It is also known that websites can be difficult to understand or inaccurate. Having a rubric can help an individual focus on the information on the site and remember to think critically.</li>
            </UnorderedList>

            <p>If the discussion allows, it might be useful to mention a caution about using the internet for information about health issues. Some sites might ask users to share personal information. Internet users should always be careful about the information they share online.</p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

